import React from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { Container } from "react-bootstrap";
import PhoneVerification from './pages/PhoneVerification';
import RedeemConfirmation from './pages/RedeemConfirmation';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
    return (
        <div className="app flex-row align-items-center bg-light">
            <div className="app-body">
                <Container>
                    <HashRouter>
                        <Routes>
                            <Route
                                path='/phoneverification/:playerId/:submittedCode/:userId'
                                element={<PhoneVerification />}
                            />
                            <Route
                                path='/redeemconfirmation/:confirmationId/:userId'
                                element={<RedeemConfirmation />}
                            />
                        </Routes>
                    </HashRouter>

                </Container>
            </div>
            <div className="ver position-absolute bottom-0 end-0 text-dark font-weight-bold" >
                ver: 0.9
            </div>
        </div >
    );
}

export default App;
