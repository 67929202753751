import axios from 'axios';
import endpoints from '../config/api/endpoints';
import dtoMapping from '../data/mapping';

export let request = axios.create({
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json'
	},
	withCredentials: false,
	validateStatus: false
});

export const dtoMapper = {
	resolve: interfaceName => dtoMapping.resolver.resolve(interfaceName),
	interfaces: dtoMapping.interfaces
};

export const isResponseValid = response => response.status === 200;
export const ENDPOINTS = endpoints;

export const toSimpleResponse = response => {
	var data = response.data;
	if (typeof data === "undefined")
		data = {};

	data.status = response.status;
	return data;
};

export const execUnauthenticatedApiAction = (apiName, dto, state, dispatch) => {
	return request.post(ENDPOINTS.actions.executeUnauthenticated + apiName, dto)
		.then(response => {
			return response;
		});
};