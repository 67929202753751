import { UNAUTH } from "../actions";

export const initial = {
    phoneVerificationComplete: null,
    redeemConfirmationEnd: null
}
export default function (state = initial, action) {
    switch (action.type) {
        case UNAUTH.COMPLETE_PHONE_VERIFICATION_UNAUTH_OK:
            {
                return {
                    ...state,
                    phoneVerificationComplete: {
                        ...action.payload
                    }
                };
            }
        case UNAUTH.COMPLETE_PHONE_VERIFICATION_UNAUTH_ERR:
            {
                return {
                    ...state,
                    phoneVerificationComplete: null
                };
            }
        case UNAUTH.REDEEM_CONFIRMATION_END_UNAUTH_OK:
            {
                return {
                    ...state,
                    redeemConfirmationEnd: {
                        ...action.payload
                    }
                };
            }
        case UNAUTH.REDEEM_CONFIRMATION_END_UNAUTH_ERR:
            {
                return {
                    ...state,
                    redeemConfirmationEnd: null
                };
            } default:
            return state;
    }
}
