import interfaces from './interfaces';
import map from 'object-mapper';

export default {
    [interfaces.iRedeemConfirmationEndActionUnauthParamsDto]: m => map(m, {
        "confirmationId": "ConfirmationId",
        "userId": "UserId"
    }),
    [interfaces.iCompletePhoneVerificationActionUnauthParamsDto]: m => map(m, {
        "playerId": "PlayerId",
        "submittedCode": "SubmittedCode",
        "userId": "UserId"
    })
};

