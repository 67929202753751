import React from 'react';
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle, faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import * as unauthenticated from '../data/selectors/unauthenticated';
import { completePhoneVerification } from '../actions/unauthenticated';
import { withParams } from '../helpers';
import { UNAUTH as actionTypes } from '../actions';


class PhoneVerification extends React.Component {
    state = {
        message: "Phone verification in progess...",
        success: null
    }

    componentDidMount() {
        this.completeVerificationCode()
    }

    render() {
        const { message, success } = this.state;
        let className = "warning";
        let icon = faExclamationCircle;
        if (success === true) {
            className = "success";
            icon = faThumbsUp
        }
        else if (success === false) {
            className = "danger";
            icon = faThumbsDown
        }
        return <Row className="justify-content-center">
            <Col md="7" lg="5">
                <div className={`alert alert-${className} align-items-center text-center`} role="alert">
                    <FontAwesomeIcon icon={icon} />
                    {message}
                </div>
            </Col>
        </Row>
    }

    completeVerificationCode() {
        const { params, completePhoneVerification } = this.props;

        if (params && params.playerId && params.userId && params.submittedCode) {
            let model = {
                playerId: params.playerId,
                submittedCode: params.submittedCode,
                userId: params.userId
            };
            return completePhoneVerification(model)
                .then(
                    action => {
                        if (action.type === actionTypes.COMPLETE_PHONE_VERIFICATION_UNAUTH_OK) {
                            const response = action.payload.response;
                            this.setState({
                                message: (response.success ? "Your phone has been verified." : response.message),
                                success: response.success
                            });
                            return;
                        }
                        else {
                            this.setState({
                                message: "Invalid data provided.",
                                success: false
                            });
                        }
                    }
                );
        }
        else {
            this.setState({ message: "Invalid parameter provided.", success: false });
        }
    }
}

export default connect(
    state => ({
        phoneVerificationComplete: unauthenticated.phoneVerificationComplete(state) || null,
    }),

    dispatch => ({
        completePhoneVerification: m => dispatch(completePhoneVerification(m)),
    })
)(withParams(PhoneVerification));
