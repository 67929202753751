import React from 'react';
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle, faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import * as unauthenticated from '../data/selectors/unauthenticated';
import { redeemConfirmationEnd } from '../actions/unauthenticated';
import { withParams } from '../helpers';
import { UNAUTH as actionTypes } from '../actions';

class RedeemConfirmation extends React.Component {
    state = {
        response: null,
        message: "Redeem confirmation in progess...",
        success: null
    }
    componentDidMount() {
        this.completeRedeemConfirmation()
    }
    render() {
        const { message, success } = this.state;
        let className = "warning";
        let icon = faExclamationCircle;
        if (success === true) {
            className = "success";
            icon = faThumbsUp
        }
        else if (success === false) {
            className = "danger";
            icon = faThumbsDown
        }
        return <Row className="justify-content-center">
            <Col md="7" lg="5">
                <div className={`alert alert-${className} align-items-center text-center`} role="alert">
                    <FontAwesomeIcon icon={icon} />
                    {message}
                </div>
            </Col>
        </Row>

    }
    completeRedeemConfirmation() {
        const { params, redeemConfirmationEnd } = this.props;

        if (params && params.confirmationId && params.userId) {
            let model = {
                confirmationId: params.confirmationId,
                userId: params.userId
            };
            return redeemConfirmationEnd(model)
                .then(
                    action => {
                        if (action.type === actionTypes.REDEEM_CONFIRMATION_END_UNAUTH_OK) {
                            const response = action.payload.response;
                            this.setState({
                                response,
                                message: "Your redeem transaction for $" + response.amount.dollarAmount + " has been approved",
                                success: true
                            });
                            return;
                        }
                        if (action.type === actionTypes.REDEEM_CONFIRMATION_END_UNAUTH_ERR) {
                            this.setState({
                                message: "The redeem confurmation is not valid.",
                                success: false
                            });
                            return;
                        }
                    }
                );
        }
        else {
            this.setState({ message: "Invalid parameter provided.", success: false });
        }
    }
}

export default connect(
    state => ({
        redeemConfirmationEnd: unauthenticated.redeemConfirmationEnd(state) || null,
    }),

    dispatch => ({
        redeemConfirmationEnd: m => dispatch(redeemConfirmationEnd(m)),
    })
)(withParams(RedeemConfirmation));
