import {
  keyMirror
} from '../helpers';

export const UNAUTH = keyMirror({
    COMPLETE_PHONE_VERIFICATION_UNAUTH: null,
    COMPLETE_PHONE_VERIFICATION_UNAUTH_OK: null,
    COMPLETE_PHONE_VERIFICATION_UNAUTH_ERR: null,

    REDEEM_CONFIRMATION_END_UNAUTH: null,
    REDEEM_CONFIRMATION_END_UNAUTH_OK: null,
    REDEEM_CONFIRMATION_END_UNAUTH_ERR: null,
});
