import { useParams } from "react-router-dom";

export const keyMirror = obj => {
	if (!obj) return {};

	for (var key in obj) obj[key] = key;

	return obj;
};

export const flattenObject = (c, d = '.') => {
	const r = {};
	(function f(o, p) {
		Object.keys(o).forEach(k => (o[k] && typeof o[k] === 'object' ? f(o[k], p ? `${p}${d}${k}` : k) : (r[p ? `${p}${d}${k}` : k] = o[k])));
	}(c));
	return r;
};

export function withParams(Component) {
	return props => <Component {...props} params={useParams()} />;
}
