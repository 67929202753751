import {
    isResponseValid,
    dtoMapper,
    execUnauthenticatedApiAction
} from './base';
import {
    UNAUTH as ActionTypes
} from './index';

export const completePhoneVerification = params => (dispatch, getState) => {
    dispatch({
        type: ActionTypes.COMPLETE_PHONE_VERIFICATION_UNAUTH
    });

    var paramsDto = dtoMapper.resolve(dtoMapper.interfaces.iCompletePhoneVerificationActionUnauthParamsDto)(params);
    return execUnauthenticatedApiAction('CompletePhoneVerification', paramsDto, getState(), dispatch)
        .then(response => {
            if (isResponseValid(response)) {
                return dispatch({
                    type: ActionTypes.COMPLETE_PHONE_VERIFICATION_UNAUTH_OK,
                    payload: {
                        status: response.status,
                        response: response.data
                    }
                });
            }
            return dispatch({
                type: ActionTypes.COMPLETE_PHONE_VERIFICATION_UNAUTH_ERR,
                payload: response.data
            });


        });
};

export const redeemConfirmationEnd = params => (dispatch, getState) => {
    dispatch({
        type: ActionTypes.REDEEM_CONFIRMATION_END_UNAUTH
    });

    var paramsDto = dtoMapper.resolve(dtoMapper.interfaces.iRedeemConfirmationEndActionUnauthParamsDto)(params);
    return execUnauthenticatedApiAction('RedeemConfirmationEnd', paramsDto, getState(), dispatch)
        .then(response => {
            if (isResponseValid(response)) {
                return dispatch({
                    type: ActionTypes.REDEEM_CONFIRMATION_END_UNAUTH_OK,
                    payload: {
                        status: response.status,
                        response: response.data
                    }
                });
            }
            return dispatch({
                type: ActionTypes.REDEEM_CONFIRMATION_END_UNAUTH_ERR,
                payload: JSON.parse(response.data)
            });
        });
};